import React, { useState, useContext, useEffect } from "react";

import bg from "../images/bg.png";
import logo from "../images/logo.png";


const Notfound = () => {
  return (
    <>
      <div
        className="bg-cover bg-no-repeat bg-center min-h-[100vh] w-full"
        style={{ backgroundImage: `url(${bg})` }}
      >

        <div className="relative h-full grid grid-rows-[55vh] grid-cols-[100vw] gap-56 md:gap-80">
          <img
            className="min-w-[300px] lg:min-w-[400px] mx-auto justify-self-center self-center text-center"
            src={logo}
          />
          <div className="flex justify-center self-center text-center text-xl font-bold  gap-2 cursor-pointer">
            ไม่พบรายละเอียด
          </div>
        </div>
      </div>
    </>
  );
};

export default Notfound;
