import axios from "axios";

const API_ENDPOINT = process.env.REACT_APP_ENDPOINT;

const getNFTByHash = async (hash) => {
    if (!hash)
        return { message: "Hash not null" };
    try {
        let response = await axios.get(`${API_ENDPOINT}/v1/reward/${hash}`);
        if (response.status === 200)
            return response.data;
        else
            return { message: "Not found" };

    }
    catch (e) {
        console.log("get nft by hash error : ", e)
        return { message: e }
    }
}

const getNFTByOwner = async (owner_address) => {
    if (!owner_address)
        return { message: "Owner not null" };
    try {
        let response = await axios.get(`${API_ENDPOINT}/v1/reward/owner/${owner_address}`);
        if (response.status === 200)
            return response.data;
        else
            return { message: "Not found" };

    }
    catch (e) {
        console.log("get nft by hash error : ", e)
        return null;
    }
}

const claimReward = async (hash, owner_address) => {
    if (!hash)
        return { message: "Hash not null" };
    try {
        let response = await axios.post(`${API_ENDPOINT}/v1/reward/${hash}/claimReward`, { claim_wallet_address: owner_address });
        if (response.status === 200)
            return response.data;
        else
            return { message: "cannot claim reward" };

    }
    catch (e) {
        console.log("claim reward error : ", e)
        return { message: e }
    }
}

const getBaseURI = async (nft_address, token_id) => {
    if (!nft_address)
        return { message: "nft_address not null" };
    if (token_id === "" || token_id === undefined)
        return { message: "token_id not null" };
    try {
        let response = await axios.get(`${API_ENDPOINT}/v1/metadata/uri/${nft_address}/${token_id}`);
        if (response.status === 200)
            return response.data;
        else
            return { message: "Not found" };

    }
    catch (e) {
        console.log("get uri error : ", e)
        return null;
    }
}

export default {
    getNFTByHash,
    claimReward,
    getNFTByOwner,
    getBaseURI
} 