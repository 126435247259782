import React, { useState, useContext, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import './App.css';
import Home from "./page/Home";
import RewardList from "./page/RewardList";
import NFTWallet from "./page/NFTWallet";
import { UserProvider, UserContext } from "./contexts/user";

import detectEthereumProvider from '@metamask/detect-provider';
import { ethers } from 'ethers'
import Notfound from "./components/Notfound";
import Welcome from "./page/Welcome";

function App() {
  const { user, setUser, setUserTokenBalance, setUserTokenApproval, tokenApprove } = useContext(UserContext);

  const getPermission = async () => {
    // check mobile support
    let provider = await detectEthereumProvider();
    if (provider) {
      provider = new ethers.providers.Web3Provider(window.ethereum);
    } else {
      window.addEventListener('ethereum#initialized', handleEthereum, {
        once: true,
      });
      setTimeout(handleEthereum, 3000)
    }


    let permission = await window.ethereum.request({
      method: 'wallet_getPermissions',
      params: [{ 'eth_accounts': {} }]
    });

    if (permission.length > 0) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      let chainId = await window.ethereum.request({ method: 'eth_chainId' });

      setUser({
        address: accounts[0],
        chainId: chainId,
        signer: provider.getSigner(),
        // tokenContract: createTokenContract(provider.getSigner()),
        // lotteryContract: createLotteryContract(provider.getSigner())
      });
    }
  }

  const handleEthereum = async () => {
    const { ethereum } = window;
    if (ethereum && ethereum.isMetaMask) {
      console.log('Ethereum successfully detected!');
    } else {
      alert('Please install MetaMask!');
    }
  }

  useEffect(() => {
    getPermission();
  }, []);

  if (process.env.REACT_APP_NODE_ENV === "production") {
    console.log = function () { }
  }


  return (
    <div className="w-full min-h-[100vh]">
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/:hash" element={<Welcome />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/welcome/:hash" element={<Welcome />} />
        <Route path="/home" element={<Home />} />
        <Route path="/home/:hash" element={<Home />} />
        <Route path="/reward/:hash" element={<RewardList />} />
        <Route path="/nftwallet" element={<NFTWallet />} />
        <Route path="/nftwallet/:hash" element={<NFTWallet />} />
        <Route path='*' element={<Notfound />} />
      </Routes>
    </div>
  );
}

export default App;
