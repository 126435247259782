const { createContext, useState, useEffect } = require("react");

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userTokenBalance, setUserTokenBalance] = useState(0)
  const [tokenApprove, setUserTokenApproval] = useState(false);;


  useEffect(() => {
    console.log(user);

  }, [user])

  return (
    <UserContext.Provider value={
      {
        user, setUser, userTokenBalance, setUserTokenBalance, tokenApprove, setUserTokenApproval
      }
    }>
      {children}
    </UserContext.Provider>
  );
};
