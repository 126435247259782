import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import bg2 from "../images/bg.png";
import logo from "../images/logo2.png";
import redeemIcon from "../images/redeemIcon.png";
import { Button } from "../components/Button";

import { UserContext } from "../contexts/user";
import { redactedAddress } from "../utils/utils";
import { faArrowLeft, faGift, faWallet } from '@fortawesome/free-solid-svg-icons'

import api from "../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RewardList = () => {
  const { user, } = useContext(UserContext);
  const [redeemStatus, setRedeemStatus] = useState(false);
  const [data, setData] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [uri, setUri] = useState(false);
  const navigate = useNavigate();
  const { hash } = useParams();
  const [load, setLoad] = useState(true)

  const getReward = async () => {
    let data = await api.getNFTByHash(hash);
    if (data?.message == undefined) {
      let base_uri = await api.getBaseURI(data.nft_address, data.token_id)
      setUri(base_uri);
      setData(data)
      console.log(data)
      setNotFound(false)
    } else {
      setNotFound(true)
    }
  }

  const claimReward = async () => {
    let data = await api.claimReward(hash, user.address);
    console.log('data', data)
    if (data?.message == "Update Reward claimed successfully") {
      setRedeemStatus(true)
    } else {
      setRedeemStatus(false)
    }
  }

  useEffect(() => {
    if (hash) {
      getReward()
      setTimeout(() => {
        setLoad(false)
      })
    }
  }, [hash]);

  useEffect(() => {
    if (!user) {
      navigate("/home/" + hash)
    }
  }, [user]);

  return (
    <>

      <div
        className="bg-cover bg-no-repeat bg-center min-h-[100vh] w-full"
        style={{ backgroundImage: `url(${bg2})` }}
      >
        <div className="py-10 px-2 sm:px-6 md:px-6 lg:px-24 xl:px-8 2xl:px-24">
          <div className="flex justify-center sm:justify-center md:justify-center lg:justify-between mb-6">
            <img alt=""
              className="min-w-[300px]"
              src={logo}
              width={400}
              height={400}
            />
            {!user?.address ?
              <div className="absolute top-[20px] right-[50px] lg:top-[70px] md:right-[100px] block text-lg font-bold">
                <span className="flex items-center">
                  {" "}
                </span>
              </div> :
              <div className="absolute top-[20px] right-[50px] lg:top-[70px] md:right-[100px] block text-lg font-bold"
              >
                <a onClick={() => {
                  if (!hash) {
                    navigate("/nftwallet/")
                    return;
                  }
                  navigate("/nftwallet/" + hash)
                }}
                >
                  <span className="flex items-center">
                    <FontAwesomeIcon icon={faWallet} className="mr-2" />
                    {redactedAddress(user?.address)}
                  </span>
                </a>
              </div>
            }
          </div>
          {notFound ?
            <div>
              <div className="grid grid-cols-5 gap-6 px-2 sm:px-2 md:px-2 lg:px-2 xl:px-2 mb-20">
                <div className="col-span-5 sm:col-span-5 md:col-span-5 lg:col-span-5 xl:col-span-2">
                  <div className="h-full w-full bg-black rounded-2xl">
                    <div className="flex justify-center p-8 h-full">

                    </div>
                  </div>
                </div>
                <div className="col-span-5 sm:col-span-5 md:col-span-5 lg:col-span-5 xl:col-span-3">
                  <div className="h-auto xl:h-[600px] w-full bg-[#ffffffe6] rounded-2xl">
                    <div className="p-4 lg:p-8 h-full rounded-2xl">
                      <div className="w-[70%] sm:w-[50%] p-2 bg-[#D60033] text-white text-center text-[1.5rem] sm:text-[1.5rem] md:text-[1.5rem] lg:text-[2rem] mx-auto xl:mx-0 mb-5 -skew-x-12 shadow-[0_8px_6px_rgba(0,0,0,0.5)]">
                        ไม่พบรางวัล
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row justify-between items-center text-2xl gap-6">
                <Button text="กลับหน้าหลัก" icon={faArrowLeft} onClick={() => navigate("/home/" + hash)} />
              </div>
            </div> :
            <div>
              {!redeemStatus ? (
                <div className="grid grid-cols-5 gap-6 px-2 sm:px-2 md:px-2 lg:px-2 xl:px-2 mb-20">
                  <div className="xl:h-[500px] col-span-5 sm:col-span-5 md:col-span-5 lg:col-span-5 xl:col-span-2">
                    <div className="h-full w-full bg-black rounded-2xl">
                      <div className="flex justify-center p-8 h-full">
                        <img alt=""
                          className="mx-auto"
                          src={uri?.image}
                          width={400}
                          height={350}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-5 sm:col-span-5 md:col-span-5 lg:col-span-5 xl:col-span-3">
                    <div className="h-auto xl:h-[500px] w-full bg-[#ffffffe6] rounded-2xl">
                      <div className="p-4 lg:p-8 h-full rounded-2xl">
                        <div className="w-[70%] sm:w-[50%] p-2 bg-[#D60033] text-white text-center text-[1.5rem] sm:text-[1.5rem] md:text-[1.5rem] lg:text-[2rem] mx-auto xl:mx-0 mb-5 -skew-x-12 shadow-[0_8px_6px_rgba(0,0,0,0.5)]">
                          รางวัลที่จะได้รับ
                        </div>

                        <div className="grid grid-flow-row auto-rows-max ">
                          {data?.reward_description?.items?.map((item, idx) => (
                            <div key={idx} className="p-2 sm:p-2 md:p-4 lg:p-4 xl:p-6">
                              <div className="flex items-center gap-6 sm:gap-12 md:gap-12 lg:gap-12 xl:gap--6">
                                <div className=" bg-white p-2 rounded-2xl">
                                  <img alt=""
                                    className="min-w-[100px] w-[100px] sm:w-[150px] md:w-[150px] lg:w-[150px] xl:w-[150px]"
                                    src={item.image_url}
                                  />
                                </div>
                                <div className="flex items-start gap-2 sm:gap-2 md:gap-6 lg:gap-6">
                                  <span className="min-w-[30px] min-h-[30px] lg:min-w-[40px] lg:min-h-[40px] rounded-[50%] bg-[#EB5757] flex justify-center items-center text-[14px] lg:text-2xl text-black font-bold">
                                    {idx + 1}
                                  </span>
                                  <div>
                                    <div className="text-black text-[16px] xl:text-2xl font-bold mb-2">
                                      {item.description}
                                    </div>
                                    <div className="text-black text-[14px] xl:text-xl">
                                      {data.reward_description.name}
                                    </div>
                                    <div className="text-black text-[12px] xl:text-xl">
                                      {data.reward_description.description}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="px-2 sm:px-2 md:px-2 lg:px-2 xl:px-2 mb-36">
                  <div className="flex justify-center items-center h-[600px] bg-black rounded-[16px]">
                    <img alt=""
                      className="min-w-[200px] lg:w-[400px]"
                      src={redeemIcon}
                    />
                  </div>
                </div>
              )}

              <div className="flex flex-col lg:flex-row justify-between items-center text-2xl gap-6">


                {!redeemStatus ?
                  <>
                    <Button text="กลับหน้าหลัก" icon={faArrowLeft} onClick={() => navigate('/home/' + hash)} />
                    <Button
                      text="แจ้งรับของรางวัล"
                      bg="bg-[#000A25]"
                      icon={faGift}
                      onClick={() => claimReward()}
                    />
                  </>
                  :
                  <>
                    <Button text="กลับหน้าหลัก" icon={faArrowLeft} onClick={() => navigate('/home/' + hash)} />
                  </>
                }
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default RewardList;
