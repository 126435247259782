import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import bg from "../images/bg.png";
import logo from "../images/innoweb3.png";
import { Button } from "../components/Button";

import { UserContext } from "../contexts/user";

import { faPersonThroughWindow, faGift } from '@fortawesome/free-solid-svg-icons'

const Welcome = () => {
  const { user, } = useContext(UserContext);
  const navigate = useNavigate();
  const { hash } = useParams();

  return (
    <>
      <div
        className="bg-cover bg-no-repeat bg-center min-h-[100vh] w-full"
        style={{ backgroundImage: `url(${bg})` }}
      >
        {!user?.address ?
          <div className="absolute top-[20px] right-[50px] lg:top-[70px] md:right-[100px] block text-lg font-bold">
            <span className="flex items-center">
              {" "}
            </span>
          </div> :
          <></>
        }
        <div className="relative h-full grid grid-rows-[40vh] grid-cols-[100vw] gap-24 md:gap-50">
          <img alt=""
            className="min-w-[300px] lg:min-w-[400px] mx-auto justify-self-center self-center text-center"
            src={logo}
          />
          <div className="grid grid-flow-row auto-rows-max justify-center self-center text-center text-xl font-bold cursor-pointer">
            <div className="">
              <Button
                text="เข้าสู่ Innoverse"
                bg="bg-[#000A25] mb-4"
                icon={faPersonThroughWindow}
                onClick={() => navigate("#")}

              />
            </div>
            <div>
              <Button
                text="แจ้งรับของรางวัล"
                bg="bg-[#000A25] mb-4"
                icon={faGift}
                onClick={() => navigate("/home/" + hash)}
              />
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Welcome;
