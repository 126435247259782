import { useContext } from "react";
import { UserContext } from "../contexts/user";
import { ethers } from "ethers";
import detectEthereumProvider from "@metamask/detect-provider";

import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ConnectWalletButton = () => {
  const { setUser } = useContext(UserContext);
  //global
  //chainId
  //address
  //signer  provider.getSigner()

  const onLogin = async (user) => {
    let provider = await detectEthereumProvider();

    const accounts = await provider
      .request({ method: "eth_requestAccounts" })
      .catch((err) => {
        if (err.code === 4001) {
          console.log("Please connect to MetaMask.");
        } else {
          console.error(err);
        }
      });

    console.log("acc", accounts);

    let chainId = await provider.request({ method: "eth_chainId" });

    if (accounts != undefined) {
      setUser({
        address: accounts[0],
        chainId: chainId,
        //   tokenContract : createTokenContract(provider.getSigner()),
        //   lotteryContract: createLotteryContract(provider.getSigner())
      });
    } else {
      console.log("Please connect to MetaMask.");
    }
  };

  function handleEthereum() {
    const { ethereum } = window;
    if (ethereum && ethereum.isMetaMask) {
      console.log("Ethereum successfully detected!");
      // Access the decentralized web!
    } else {
      alert("Please install MetaMask!");
    }
  }

  return (
    <>
      <div
        className="flex justify-center self-center text-center text-[16px] lg:text-xl font-bold  gap-2 cursor-pointer mb-4"
        onClick={onLogin}
      >
        <FontAwesomeIcon icon={faLock} className="mt-1" />
        กรุณาล็อคอินกระเป๋า Metamask ของท่าน
      </div>
    </>
  );
};

export { ConnectWalletButton };
