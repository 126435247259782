import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import bg from "../images/bg.png";
import logo from "../images/logo.png";
import { Button } from "../components/Button";

import { UserContext } from "../contexts/user";
import { redactedAddress } from "../utils/utils";
import { ConnectWalletButton } from "../components/ConnectWallet";
import api from "../utils/api";
import { faImage, faGift, faWallet } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ItemList } from "../components/ItemList";

const Home = () => {
  const { user } = useContext(UserContext);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const { hash } = useParams();
  const [claimStatus, setClaimStatus] = useState(false)

  const getClaimedStatus = async () => {
    let data = await api.getNFTByHash(hash);
    if (data?.message == undefined) {
      setData(data)
    }
  }

  useEffect(() => {
    if (hash) {
      getClaimedStatus()
    }
  }, [hash]);

  useEffect(() => {
    if (user?.chainId !== process.env.REACT_APP_CHAIN_ID || data?.claimed || !hash) {
      setClaimStatus(false);
    } else {
      setClaimStatus(true);
    }
  }, [hash, user, data]);

  return (
    <>
      <div
        className="bg-cover bg-no-repeat bg-center min-h-[100vh] w-full"
        style={{ backgroundImage: `url(${bg})` }}
      >
        {user?.chainId !== process.env.REACT_APP_CHAIN_ID ? (<div className="text-white font-medium sticky bg-[#d32e4a] text-center h-6"><marquee>⚠️ Please Connect to Bitkub Mainnet ⚠️</marquee></div>) : ("")}
        {!user?.address ?
          <div className="absolute top-[20px] right-[50px] lg:top-[70px] md:right-[100px] block text-lg font-bold">
            <span className="flex items-center">
              {" "}
            </span>
          </div> :
          <div className="absolute top-[20px] right-[50px] lg:top-[70px] md:right-[100px] block text-lg font-bold" >
            <span className="flex items-center">
              <FontAwesomeIcon icon={faWallet} className="mr-2" />
              {redactedAddress(user?.address)}
            </span>
          </div>
        }
        <div className="relative h-full grid grid-rows-[45vh] grid-cols-[100vw] gap-56 md:gap-80">
          <img alt=""
            className="min-w-[300px] lg:min-w-[400px] mx-auto justify-self-center self-center text-center"
            src={logo}
          />
          <div className="flex justify-center bg-gradient-to-b from-transparent via-[#010224] to-[#010224]">
            <div className="flex-col">
              <div className="flex justify-center">
                {!user?.address ? (
                  <>
                    <ConnectWalletButton />
                  </>
                ) : (
                  <>
                    {(data?.claimed && data?.transferred) ? (
                      <Button
                        text="ดู NFT ของคุณ"
                        bg="bg-[#000A25] mb-4"
                        icon={faImage}
                        onClick={() => navigate("/nftwallet")}
                      />
                    ) : (
                      data?.claimed || !hash ? <>
                        <Button
                          text="ดู NFT ของคุณ"
                          bg="bg-[#000A25] mb-4"
                          icon={faImage}
                          onClick={() => navigate("/nftwallet")}
                        /></> : <>
                        <Button
                          text="กดรับของรางวัล"
                          bg="bg-[#000A25] mb-4"
                          icon={faGift}
                          onClick={() => {
                            if (!claimStatus)
                              return;
                            if (!hash) {
                              navigate(`/reward/`)
                              return;
                            }
                            navigate(`/reward/${hash}`)
                          }
                          }
                        />
                      </>
                    )}
                  </>
                )}
              </div>

              <div className="w-[70%] sm:w-[70%] mt-16 p-2 bg-[#D60033] text-white text-center text-[1.3rem] mx-auto mb-5 -skew-x-12 shadow-[0_8px_6px_rgba(0,0,0,0.5)]">
                ของรางวัลทั้งหมด
              </div>
              <div className="mt-20">
                <ItemList />
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Home;
