import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Button = ({ text, bg, icon, onClick }) => {
  return (
    <>
      <div
        className={`${
          bg ? bg : "bg-transparent"
        } flex justify-center w-[300px] sm:w-[300px] md:w-[300px] lg:w-[400px] p-3 sm:p-3 md:p-3 lg:p-5  border-solid border-[4px] hover:border-[#56CCF2] rounded-[100px] gap-2 text-xl cursor-pointer font-bold`}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={icon} />
        {text}
      </div>
    </>
  );
};

export { Button };
