import React from "react";

const ItemList = () => {
    const data = [
        {
            name: "เงินรางวัล",
            imageUrl: "https://codecleandev.sgp1.cdn.digitaloceanspaces.com/ptt_nft/rewards/Money5000.png",
            quantity: 26
        },
        {
            name: "ตุ๊กตา Godji",
            imageUrl: "https://codecleandev.sgp1.cdn.digitaloceanspaces.com/ptt_nft/rewards/GodjiDoll.jpg",
            quantity: 9
        },
        {
            name: "กระเป๋าแคนวาส Godji",
            imageUrl: "https://codecleandev.sgp1.cdn.digitaloceanspaces.com/ptt_nft/rewards/Gift012.png",
            quantity: 20
        },
        {
            name: "กระเป๋าผ้าพับเก็บ Godji",
            imageUrl: "https://codecleandev.sgp1.cdn.digitaloceanspaces.com/ptt_nft/rewards/Gift023.png",
            quantity: 4
        },
        {
            name: "Mouse Godji",
            imageUrl: "https://codecleandev.sgp1.cdn.digitaloceanspaces.com/ptt_nft/rewards/Mouse.png",
            quantity: 20
        },
        {
            name: "ปากกา Godji",
            imageUrl: "https://codecleandev.sgp1.cdn.digitaloceanspaces.com/ptt_nft/rewards/Gift006.png",
            quantity: 66
        },
        {
            name: "กระบอกน้ำ Godji",
            imageUrl: "https://codecleandev.sgp1.cdn.digitaloceanspaces.com/ptt_nft/rewards/Gift005.png",
            quantity: 10
        },
        {
            name: "กระดาษโนต Godji",
            imageUrl: "https://codecleandev.sgp1.cdn.digitaloceanspaces.com/ptt_nft/rewards/Gift010.png",
            quantity: 10
        },
        {
            name: "ผ้าปิดจมูก Godji",
            imageUrl: "https://codecleandev.sgp1.cdn.digitaloceanspaces.com/ptt_nft/rewards/Gift013.png",
            quantity: 10
        },
        {
            name: "นาฬิกาปลุก Godji",
            imageUrl: "https://codecleandev.sgp1.cdn.digitaloceanspaces.com/ptt_nft/rewards/Gift018.png",
            quantity: 5
        },
        {
            name: "หมอนรองคอ Godji",
            imageUrl: "https://codecleandev.sgp1.cdn.digitaloceanspaces.com/ptt_nft/rewards/Gift019.png",
            quantity: 10
        }
    ];

    return (
        <>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {data?.map((item, idx) => (
                    <>
                        <div className="grid grid-rows-3 grid-cols-3" key={idx}>
                            <div className="row-span-2 justify-items-center mx-auto">
                                <img className="max-h-32 mb-4 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110" src={item.imageUrl} alt={item.name}>
                                </img>
                            </div>
                            <div className="ml-8 col-span-2 row-span-2">
                                <div className="text-xl ">{item.name}</div>
                                <div className="text-red-500">จำนวน {item.quantity} รางวัล</div>
                            </div>
                            <div className="lineItem col-span-3 mx-auto"></div>

                        </div>
                    </>
                ))}
            </div>
        </>
    );
};

export { ItemList };
