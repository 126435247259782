const { createContext, useState, useEffect } = require("react");

export const LoaderContext = createContext();

export const LoaderProvider = ({ children }) => {
  const [loadingItem, setLoadingItem] = useState([]);

  useEffect( () => {
    console.log(loadingItem);

  },[loadingItem])

  return (
    <LoaderContext.Provider value={{loadingItem, setLoadingItem}}>
      {children}
    </LoaderContext.Provider>
  );
};
