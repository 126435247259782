import axios from "axios";
import { ethers } from 'ethers';
import moment from "moment";

export const redactedAddress = (address) => {
    if (!address) return "....";
    return address.substring(0, 5) + "..." + address.substring(address.length - 5);
}

export const getHTTPfromIPFS = (ipfs) => {
    return "https://ipfs.io/ipfs/" + ipfs.split("\/\/")[1];
}

export const getImageFromJson = async (ipfs) => {
    let res = await axios.get(getHTTPfromIPFS(ipfs));
    return await getHTTPfromIPFS(res.data.image);
}

export const bigIntToNumber = (bigInt) => {
    if (bigInt == null) return 0;
    // return bigInt.div(1e18.toString()).toNumber();
    return parseFloat(ethers.utils.formatEther(bigInt)).toFixed(2)
}

export const calculatevotingProgress = (amount1, amount2) => {
    amount1 = parseInt(amount1)
    amount2 = parseInt(amount2);
    if (amount2 === 0) return 0;
    return ((amount1 * 100) / (amount2));
}

export const formatCurrency = (amount) => {
    let value = parseFloat(amount).toFixed(1);
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

